import { StaticQuery, graphql } from "gatsby";
import React, { useMemo } from "react";
import { FeaturedCard, TypeTag } from "./styles/webinarsPageStyles";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import TextLinkV2 from "../common/TextLinkV2";
import { animated, useSpring } from "@react-spring/web";
import { Link } from "gatsby";
import { chooseEventToFeature, getType } from "./util/webinarsFunctions";
import DateRow from "components/hybrid-event/DateRow";

export default function FeaturedWebinar() {
  const renderContent = data => {
    const { webinar, introWebinar, hybridEvent } = data;
    const { edges: _featuredWebinar } = webinar;
    const { edges: _introWebinar } = introWebinar;
    const { edges: _featuredHybridEvent } = hybridEvent;
    const featuredEvent = chooseEventToFeature(
      _featuredWebinar,
      _introWebinar,
      _featuredHybridEvent
    );
    const date =
      featuredEvent[0].node.frontmatter.startDate ||
      featuredEvent[0].node.frontmatter.dateInfo.startDate;
    const type = getType(featuredEvent[0].node.frontmatter.type, date);
    const image = featuredEvent[0].node.frontmatter.cardImage;
    const title = featuredEvent[0].node.frontmatter.title;
    const blurb =
      (featuredEvent[0].node.frontmatter.blurb ||
        featuredEvent[0].node.frontmatter?.eventDetails?.eventSummary) ??
      "";
    const slug = featuredEvent[0].node.fields.slug;

    const timeZone =
      featuredEvent[0].node.frontmatter.dateInfo?.timeZone ??
      "America/Los_Angeles";

    const [fadeIn] = useSpring(
      () => ({
        from: {
          opacity: 0,
          transform: "translateY(2.5rem)",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        },
        to: { opacity: 1, transform: "translateY(0rem)" },
        config: { mass: 3, bounce: 1, duration: 500 },
      }),
      []
    );

    const linkText = useMemo(() => {
      return type === "Live Webinar" ? "Register Now" : "Watch Now";
    }, [type]);

    return (
      <animated.div style={fadeIn}>
        <FeaturedCard as={Link} to={slug}>
          <div className="image-wrapper">
            <TypeTag>{type}</TypeTag>
            <img src={image} alt={title} />
          </div>
          <div className="card-body">
            <DateRow date={date} timeZone={timeZone} type={type} />
            <TitleMed>{title}</TitleMed>
            <MainParagraph>{blurb}</MainParagraph>
          </div>
          <div className="card-footer">
            <TextLinkV2 title={linkText} color="var(--blue-500)" path={slug} />
          </div>
        </FeaturedCard>
      </animated.div>
    );
  };

  const query = graphql`
    query {
      webinar: allMarkdownRemark(
        limit: 1
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            templateKey: { eq: "webinar-post" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              type
              cardImage
              startDate
              title
              blurb
            }
          }
        }
      }
      introWebinar: allMarkdownRemark(
        limit: 1
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            templateKey: { eq: "intro-webinar-post" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              type
              cardImage
              startDate
              title
              blurb
            }
          }
        }
      }
      hybridEvent: allMarkdownRemark(
        limit: 1
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            templateKey: { eq: "hybrid-event" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              title
              cardImage
              topics
              dateInfo {
                startDate
                timeZone
              }
              eventDetails {
                eventSummary
              }
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
